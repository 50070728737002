import fetchGql from '@/utils/fetchGql'
import { isEn } from "@/utils/helpers";

const query = gql`
  query InvestoreventsItems($language: String, $slug: String) {
    InvestoreventsItems(resolve_links: "url", starts_with: $language, by_slugs: $slug) {
      items {
        content {
          body
          date
          dateSubText
          location
        }
        slug
      }
    }
  }
`

export const useFetchInvestorEvent = () => {
  const fetchInvestorEvent = (language: string, slug?: string) => {
    const variables = {
      language: isEn(language) ? '' : `${language}/*`,
      slug: `corporate/investors-airbaltic-com/events/${slug}`
    }

    return fetchGql(query, variables).then((response: any) => {
      return response.InvestoreventsItems.items[0].content
    })
  }

  return { fetchInvestorEvent }
}
